import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { z } from 'zod';
import { AddressSchemaType } from './address.schema';
import isEqual from 'lodash.isequal';

export type IZPhoneNumberOptions = {
  optional?: boolean;
};

export const zPhoneNumber = (options: IZPhoneNumberOptions = {}) =>
  z.string().transform((arg, ctx) => {
    if (arg === '') return undefined;

    // for optional, skip when there's no value
    const { optional = false } = options;
    if (optional && !arg) {
      return arg;
    }

    const phone = parsePhoneNumberFromString(arg, {
      // set this to use a default country when the phone number omits country code
      defaultCountry: 'CA',

      // set to false to require that the whole string is exactly a phone number,
      // otherwise, it will search for a phone number anywhere within the string
      extract: false,
    });

    if (phone && phone.isValid()) {
      return phone.number;
    }

    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'labels.invalidPhoneNumber',
    });
    return z.NEVER;
  });

export const zValidateAddress = (
  address: AddressSchemaType['address'],
  ctx: z.RefinementCtx,
) => {
  const { value, options = [] } = address;

  const availableCoordinates = options
    ?.map((location) => location.coordinates)
    .filter((coordinates) => coordinates.every(Number));

  if (!value?.coordinates?.every(Number) && !availableCoordinates.length) {
    ctx.addIssue({
      code: 'custom',
      message: 'form.required',
      path: ['address'],
    });
  } else if (
    //Check if the selected address
    //is not in the available options
    !availableCoordinates.find((coordinates) =>
      isEqual(value?.coordinates, coordinates),
    )
  ) {
    ctx.addIssue({
      code: 'custom',
      message: 'form.selectAddressFromOptions',
      path: ['address'],
    });
  }
};
